<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';
import { FlyOutBasketLabels } from './flyOutBasket.types';
import { DsAlert } from '@coloplast/design-system';

defineProps<{
	labels: FlyOutBasketLabels,
}>()

const store = useStore();

const basket = computed(() => store.getters.getBasket);
</script>

<template>
	<div>
		<dl class="ds-datalist ds-datalist--side-by-side">
			<dt class="ds-datalist__title">{{ labels.shippingLabel }}</dt>
			<dd class="ds-datalist__text ds-datalist--nums">
				{{ basket.shippingTotal > 0 ? basket.formattedShippingTotal : labels.freeShippingLabel }}
			</dd>

			<dt class="ds-datalist__title">{{ labels.partialPaymentLabel }}</dt>
			<dd class="ds-datalist__text ds-datalist--nums">{{ labels.partialPaymentText }}</dd>
		</dl>

		<DsAlert
			appearance="neutral-alt"
			:text="labels.partialPaymentDescription"
		/>
	</div>
</template>
